<template>
  <div>
    <b-modal
      ref="delete-transfer-modal"
      hide-footer
      :title="title"
      id="modal-prevent-closing"
    >
      <b-form-group class="required-control">
        <label>Mô tả lý do xóa phiếu:</label>
        <b-form-textarea
          size="sm"
          v-model="$v.form.deleteReason.$model"
          :placeholder="'Thêm mô tả lý do xóa...'"
          :rows="6"
          :max-rows="6"
          :state="validateState('deleteReason')"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-live-feedback"
          >Vui lòng nhập lý do hủy</b-form-invalid-feedback
        >
      </b-form-group>
      <b-button
        style="fontweight: 600; width: 70px"
        variant="primary"
        size="sm"
        @click="onSubmit"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import { makeToastSuccess, makeToastFaile } from './../../../utils/common';

export default {
  props: ['stockSlipId', 'stockSlipCode'],
  mixins: [validationMixin],
  data() {
    return {
      title: '',
      form: {
        deleteReason: '',
      },
    };
  },
  validations: {
    form: {
      deleteReason: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    showModal: function () {
      this.title = `Xóa phiếu chuyển kho: ${this.stockSlipCode}`;
      this.$refs['delete-transfer-modal'].show();
    },
    hideModal: function () {
      this.$refs['delete-transfer-modal'].hide();
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const data = {
        id: this.stockSlipId,
        deleteReason: this.$v.form.deleteReason.$model,
      };

      ApiService.put('stocks/delete-transfer-stock-reason', data).then(
        ({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.hideModal();
            this.$emit('onSuccess', true);
          } else {
            makeToastFaile(data.message);
          }
        }
      );
    },
  },
};
</script>